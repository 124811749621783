import React from "react";
import { ContextProvider } from "./Context/ContextProvider";
import { ResetStyles } from "./Global/ResetStyles";
import { MainScreen } from "./MainScreen/MainScreen";
import { AppThemeProvider } from "./Themes/ThemeProvider";

export const App = () => {
  return (
    <>
      <ResetStyles />
      
        <ContextProvider>
          <AppThemeProvider>
            <MainScreen>
            </MainScreen>
          </AppThemeProvider>
        </ContextProvider>
    </>
  );
}


