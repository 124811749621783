import React from 'react'
import { A,  B, Button, P } from '../Global/GlobalStyles'
import { FormattedMessage } from 'react-intl';
import { Container, LinksContainer, Name, Picture, Section, Subtitle } from './styles'

export const InitialSection = ({ id, title }) => {

    const cvEsp = 'https://drive.google.com/file/d/14CfjT5ncQeGE3B77XYt6D4eB6jR3PvNN/view';
    // const cvEng = 'https://drive.google.com/file/d/14CfjT5ncQeGE3B77XYt6D4eB6jR3PvNN/view';

    return (
        <Section id={id}>
            <Container>
                <Subtitle id={title}>
                    <FormattedMessage id="Hola" />
                    <br />
                    <FormattedMessage id="Mi.nombre.es" />
                </Subtitle>

                <Name>Ariel</Name>

                <P color={'contrast'}>
                    <FormattedMessage id="Soy.estudiante.de" />
                    <B primary>
                        <FormattedMessage id="Ingenieria.en.sistemas" />
                    </B>
                    <FormattedMessage id="en.la.universidad" />
                    <B primary>Frontend </B>
                    <FormattedMessage id="con.sede.en" />
                </P>

                <Button as="a" href={cvEsp} target="_blank" rel="noopener noreferrer" width={'180px'} height={'46px'} margin={'25px 0 0 0'} style={{paddingLeft:"11px"}}>
                    <FormattedMessage id="Descargar.CV" />
                </Button>
                <br />
                <LinksContainer>
                    <A href='https://www.linkedin.com/in/arsitta/' target='_blank'>LINKEDIN</A>
                    <A href='https://github.com/arsitta' target='_blank'>GITHUB</A>
                </LinksContainer>

            </Container>
            <Picture />
        </Section>
    )
}
