export const ID_SECTIONS = [
    'Inicio',
    'Servicios',
    'Skills',
    'Experiencia',
    'Contacto' 
]


//Para activar show efect del titulo
export const TITLE_SECTIONS = ID_SECTIONS.map(e => 'Title-' + e);

//Para activar show efect de los items de skills
export const OBSERVABLES_UNICA_VEZ = [
    'Item1',
    'Item2',
    'Item3',
]

export const OBSERVABLES_ITERADAS = [
        ...TITLE_SECTIONS
]

// Para cargar TODO lo que querramos observar
export const OBSERVABLES = [
    ...OBSERVABLES_ITERADAS,
    ...OBSERVABLES_UNICA_VEZ,
    ...ID_SECTIONS,
]

export const zIndex = {
    modal: 6,
    sobretodo: 5,
    wrapper: 4,
    arriba: 3,
    abajo: 2,
    subtodo: 1,
}



