import styled from 'styled-components';
import avatar from '../assets/avatar.svg'

export const Section = styled.section`
    background-color: ${props => props.theme.contrastCommonBG};
    padding: 0px 10px; 
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 11fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'a body c';
    height: calc(100vh - 55px);
    min-height: 420px;
    align-items:center;
    @media (min-width: 760px) {
        grid-template-areas: 'a body b picture c';
        grid-template-columns: 4fr 11fr 2fr 11fr 4fr;
    }

    @media (min-width: 1024px) {
        height:100vh;
    }
`

export const Container = styled.div`
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 600px;
`

export const Name = styled.h1`
        font-size: 6rem;
        font-weight: 700;
        margin: 10px 0;
        
    @media (min-width: 760px) {
        font-size: 7rem;
    }
`

export const Subtitle = styled.h3`
    font-size: 2rem;
    font-weight: 400;
    color: ${props => props.theme.blackText};
`

export const GeneralContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`


export const LinksContainer = styled.div`

`

export const Picture = styled.div`
    display:none;
    align-self: center;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-image: url(${avatar});
    background-size: cover;
    grid-area: picture;
    @media (min-width: 760px) {
        display:block;
    }
`

