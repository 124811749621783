import React, { useState } from 'react'
import { TopNavBar, SvgMenuIcon, ModalNavMenu, TopNavBarBG, NavigationBarComponent, Lista, LangButton, Hr, LangSwitch, Link, LinkContainer, LinkLang, DivRow } from './styles'
import { FormattedMessage } from 'react-intl';

import { ContextConsumer } from '../Context/ContextConsumer';
import { ProgressBar } from './ProgressBar';
import { ID_SECTIONS } from '../Global/appConfig';
import { BottomNavIndicator } from './BottomNavIndicator';
import { NavLink } from './NavLink';
import { AlternativeData } from './AlternativeData';

export const NavigationBar = () => {

    const { actualSectionNumber, setActualSectionNumber, lang, setLang } = ContextConsumer();

    const [showModal, setShowModal] = useState(false)

    const sections = ID_SECTIONS;
    const numberOfSections = ID_SECTIONS.length;
    const actualSectionName = ID_SECTIONS[actualSectionNumber]

    const handleShowMenu = () => {
        setShowModal(status => !status)
    }

    const handleLangChange = (buttonLang) => {
        if (lang !== buttonLang) {
            setLang(buttonLang)
        }
    }

    return (

        <NavigationBarComponent>

            <TopNavBar>

                <SvgMenuIcon show={showModal}
                    onClick={handleShowMenu}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </SvgMenuIcon>

                <ProgressBar
                    actualSectionName={actualSectionName}
                    actualSectionNumber={actualSectionNumber}
                    numberOfSections={numberOfSections}
                />
            </TopNavBar>

            <AlternativeData />

            <TopNavBarBG />
            <BottomNavIndicator moveScrollIndicator={showModal} actualSectionNumber={actualSectionNumber} />
            <ModalNavMenu show={showModal}>


                <Lista>
                    <LangSwitch>
                        <DivRow>
                            <FormattedMessage id={'Idioma:'} />
                            <LinkContainer>
                                <LinkLang selected={lang == 'es-AR' ? true : false} onClick={() => handleLangChange('es-AR')}>
                                    ES
                                </LinkLang>
                            </LinkContainer>
                            |
                            <LinkContainer>
                                <LinkLang selected={lang == 'en-US' ? true : false} onClick={() => handleLangChange('en-US')}>
                                    EN
                                </LinkLang>
                            </LinkContainer>
                        </DivRow>
                        <Hr />
                    </LangSwitch>



                    {sections.map((section, index) =>
                        <NavLink
                            key={`${section} ${index}`}
                            sectionName={section}
                            sectionNumber={index}
                            actualSectionNumber={actualSectionNumber}
                            setActualSectionNumber={setActualSectionNumber}
                            onClickEvent={() => setShowModal(false)}
                        />)}
                </Lista>

            </ModalNavMenu>

        </NavigationBarComponent>


    )
}
