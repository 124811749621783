import {useState, useRef, useEffect} from 'react'

export const useObserver = (options) => {

    const [elements, setElements] = useState([]);
    const [entries, setEntries] = useState([]);
    
    const observer = useRef(new IntersectionObserver ((newEntries) => {setEntries(newEntries)}, options));

    useEffect ( () => {
        const currentObserver = observer.current;
        currentObserver.disconnect();
        (elements.length > 0) && elements.forEach(element => currentObserver.observe(element));

        return () => {
             currentObserver && observer.current.disconnect();
        }
    }, [elements]) 

   
    return [observer.current, setElements, entries];
}

