import React from 'react'
import { P } from '../Global/GlobalStyles'
import { LogosSkills } from './LogosSkills'
import { SubSection, Div } from './styles'
import { Section, SectionContainer } from '../Global/GlobalStyles';
import { FormattedMessage } from 'react-intl';
import { SectionTitle } from '../Component/SectionTitle';

export const SkillsSection = ({ id, title, }) => {
    return (
        <Section id={id} bgColor={'outsideColorBG2'}>
            <SectionContainer >
                <SectionTitle id={title}  bgColor={'outsideColorBG2'}>
                    Skills
                </SectionTitle>

                <P margin={'5px 0px'}>
                    🛠️<FormattedMessage id={"Las.herramientras.que"} />
                </P>

                <LogosSkills />

                <SubSection>
                    <Div>
                        <P>
                        <FormattedMessage id={"Amo.los.proyectos.desafiantes"} /> 💕
                        </P>
                    </Div>
                    <Div>
                        <P>
                            📚 <FormattedMessage id={"Actualmente.estoy.estudiando"} />
                            <br />
                            -Next<br />
                            -React Native<br />
                        </P>
                    </Div>
                </SubSection>

            </SectionContainer>
        </Section>
    )
}


