import styled from 'styled-components';
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';

export const DivBadgeTechs = ({ techs, elementKey = "" }) => {
    return (
        <DivTechs>
            {techs.map((ctTech, index) =>
                <React.Fragment key={elementKey + "-" + ctTech + "-" + index}>
                    <BadgeTech >{ctTech}</BadgeTech>
                </React.Fragment>
            )}
        </DivTechs>
    )
}

export const Experience = ({ experiencia, startOpened }) => {
    const [showData, setShowData] = useState(false)
    const contentRef = useRef();

    useEffect(() => {
        if (startOpened) setShowData(startOpened)
    }, [startOpened])

    return (

        <LiExperiencia
            onClick={() => setShowData(st => !st)}
        >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <H2Nombre showData={showData}>{experiencia.nombre}</H2Nombre>
                    <H3Rol breakpointMin={570} showData={showData}>{experiencia.rol}</H3Rol>
                </div>
                <PFecha breakpointMin={780}>{experiencia.fecha}</PFecha>
            </div>

            <DivExpandable ref={contentRef}
                style={showData ? { height: contentRef.current.scrollHeight + "px" } : { height: "0px" }}
            >
                <H3Rol breakpointMax={569} showData={showData} style={{
                    opacity: showData ? 1 : 0,
                    transitionDelay: showData ? "50ms" : "350ms",
                    transitionDuration: "300ms",
                    transitionProperty: "opacity",
                    transitionTimingFunction: "ease",
                }}
                >{experiencia.rol}</H3Rol>
                <P style={{
                    opacity: showData ? 1 : 0,
                    transitionDelay: showData ? "100ms" : "300ms"
                }}>
                    {experiencia.descripcion}
                </P>
                <SectionTechs style={{ opacity: showData ? 1 : 0, transitionDelay: showData ? "200ms" : "200ms" }}>
                    <TechsTitle><FormattedMessage id={'UTILIZADAS'} />:</TechsTitle>
                    <DivBadgeTechs techs={experiencia.techs} elementKey="experiencias" />
                </SectionTechs>
                <PFecha breakpointMax={779} style={{
                    opacity: showData ? 1 : 0,
                    transitionDelay: showData ? "300ms" : "100ms",
                    transitionDuration: "300ms",
                    transitionProperty: "opacity",
                    transitionTimingFunction: "ease",
                }}>{experiencia.fecha}</PFecha>
            </DivExpandable>
        </LiExperiencia >
    )
}

const LiExperiencia = styled.li`
    list-style: none;
    border-bottom: 2px solid white;
    padding: 4rem 2rem 3rem 2rem;
    margin: 0 -1rem;
    cursor:pointer;
    overflow:hidden;
    
    &:hover {
        background-color:#282a3640;
    }

    @media(min-width:680px){
        margin: 0;
    }

`

const DivExpandable = styled.div`
transition-duration: 0.9s;
transition-property: height;
transition-timing-function: ease;
height: 200px;
`

const H2Nombre = styled.h2`
    display:flex;
    margin-left:2rem;
    color:#bfc3cc;
    font-size:2.5rem;
    color: ${props => props.showData ? props.theme.primaryColor : props.theme.primaryCommon};
    position:relative;
    align-items: center;

    &::after {
        content: '';
        display:flex;
        position: absolute;
        background-color: ${props => props.showData ? props.theme.primaryColor : props.theme.primaryCommon};
        width: 1rem;
        height: 1rem;
        color:green;
        right: calc(100% + 0.7rem);
        border-radius:50%;
    }
`

const H3Rol = styled.h3`
    color:#bfc3cc;
    font-size:1.6rem; 
    font-weight: 400;
    color: ${props => props.showData ? props.theme.primaryColor : props.theme.primaryCommon};
    border: 1px solid ${props => props.showData ? props.theme.darkPrimaryCommon : props.theme.primaryCommon};
    border-radius: 18px;
    padding: 4px 10px;
    margin-left:2rem;
    display:none;
    ${props => props.breakpointMax ? `@media(max-width:${props.breakpointMax}px){display:block; width:fit-content; margin:1rem 0 1rem 0;}` : ""}
    ${props => props.breakpointMin ? `@media(min-width:${props.breakpointMin}px){display:block}` : ""}
`

const PFecha = styled.p`
    color:#bfc3cca6;
    font-size:1.8rem;
    font-weight: 500;
    display:none;
    ${props => props.breakpointMax ? `@media(max-width:${props.breakpointMax}px){display:block}; margin-top:1rem` : ""}
    ${props => props.breakpointMin ? `@media(min-width:${props.breakpointMin}px){display:block}` : ""}
`

const P = styled.p`
    color:#bfc3cc;
    margin-top:8px;
    font-size:1.6rem;
    transition-duration: 300ms;
    transition-property: opacity;
    transition-timing-function: ease;
`

const SectionTechs = styled.section`
    color:#bfc3cc;
    margin-top:2rem;
    font-size:1.6rem;
    display:flex;
    transition-duration: 300ms;
    transition-property: opacity;
    transition-timing-function: ease;
`
const TechsTitle = styled.p`
    min-width: max-content;
    margin-right:1rem;
`

const DivTechs = styled.div`
    display:flex;
    flex-wrap:wrap;
    color:#bfc3cc;
    font-size:1.6rem;
`

const BadgeTech = styled.span`
    border:1px solid white;
    border-radius: 16px;
    margin: 0 0.5rem 1rem;
    padding: 1px 8px;
    font-size: 1.3rem;
`
