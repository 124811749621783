import React from 'react'
import { IntlProvider } from 'react-intl';
import { ContextConsumer } from '../Context/ContextConsumer';

import TranslateEN from './en-US.json';
import TranslateES from './es-AR.json';

export const LangProvider = ({ children }) => {

    const { lang } = ContextConsumer();

    const messages = {
        'es-AR': TranslateES,
        'en-US': TranslateEN
    }

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            {children}
        </IntlProvider>
    )
}
