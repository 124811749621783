import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Description, ItemSvg, Subtitle, Title } from './itemStyles';

const timeDraw = 2.4;
const delayInicial = 0.2;
const timeAnimation = timeDraw + delayInicial;

export const Item2 = ({ show, id, omitAnimation }) => {

  return (
    <>

      <Svg omitAnimation={omitAnimation} id='Item2' show={show} xmlns="http://www.w3.org/2000/svg" viewBox="260 85 100 450">
        <Path white d="M434.48,160.43h50.38c18,0,18,23.48,18,23.48V403.54c0,27.11-22.36,25.07-22.36,25.07H138.13c-21.39,0-18.22-27.13-18.22-27.13V183.91s-.7-23.48,21.54-23.48H305.09" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Path d="M305.09,186H150.15s-4.49-.33-4.58,4.73,0,182.42,0,182.42.39,5.18,5.67,5.18H469.83s6.68,1,6.68-6.69V194.09s.26-5.89-5.88-5.89H432.91" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path white d="M307.43,138.78V290.67c0,14.94,7.05,25.76,21.66,25.76h82.43c12.52,0,21.37-7.3,21.37-24.52S432.78,151,432.78,141c0-7.75,2.94-28.3-20.93-28.3H328.57S307.43,113,307.43,138.78Z" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Line x1="311.02" y1="139.24" x2="429.85" y2="139.24" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="310.93" y1="290.67" x2="432.89" y2="290.67" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path white d="M303.52,226.17H198.07s-21-.06-21,21v84.69s1,20.65,20.66,20.65H360.13s21-3.65,21-21V319.63" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Line x1="202.57" y1="229.97" x2="202.57" y2="348.26" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="349.96" y1="321.03" x2="349.96" y2="349.57" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path white d="M245.87,432.52s-.78,18.89,14.35,18.89H360.39S376,450.78,376,431.48" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Path white d="M376,486.26H243.26s-10.12.16-10.12,10.12,5.79,11.22,11.22,11.22H376s13.32,1.67,13.32-10.49S381.24,486.26,376,486.26Z" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Line white x1="280.83" y1="453.39" x2="280.83" y2="483.65" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Line white x1="342.13" y1="453.39" x2="342.13" y2="483.65" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
        <Circle white cx="311.28" cy="404.87" r="8.26" style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '9px' }} />
      </Svg>

      <Description id='Item2'>
        <Title
          timeAnimation={timeAnimation}
          omitAnimation={omitAnimation}
          show={show}
        >
          <FormattedMessage id="Adaptables.a.distintas.plataformas" />
        </Title>
        <Subtitle
          timeAnimation={timeAnimation}
          omitAnimation={omitAnimation}
          show={show}>
          <FormattedMessage id="Diseños.desarrollados.para" />
        </Subtitle>
      </Description>

    </>


  )
}

const Line = styled.line`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:130px; 
`

const Path = styled.path`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:1140px; 
`

const Circle = styled.circle`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
`

const Svg = styled(ItemSvg)`

${props => !props.omitAnimation && (
    css`
          & Line {
            stroke-dashoffset:130px;
          }
          & Path {
            stroke-dashoffset:1140px;
          }
          & Circle {
            opacity: 0;
          }

          ${props => props.show && css`
              & Path, Line
              {
                stroke-dashoffset:0;
                transition: all ${timeAnimation + 's'} ease-out;
              }

              & Circle
              {
                opacity:1;
                transition: all 500ms ease-out;
                transition-delay: ${(timeAnimation - 0.6) + 's'};
              }
              `
      }
          `
  )
  }
      
`