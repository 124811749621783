import { useState, useRef, useEffect } from 'react'

export const useResize = () => {

    const [elements, setElements] = useState([]);
    const [entries, setEntries] = useState([]);

    const observerSize = useRef(new ResizeObserver((newEntries) => setEntries(newEntries)));

    useEffect(() => {
        const currentObserver = observerSize.current;
        currentObserver.disconnect();
        (elements.length > 0) && elements.forEach(element => currentObserver.observe(element));

        return () => {
            currentObserver && observerSize.current.disconnect();
        }
    }, [elements])


    return [observerSize.current, setElements, entries];
}
