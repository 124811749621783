import React from 'react'

import { LangProvider } from '../lang/LangProvider'

import { PageSections } from '../PageSections/PageSections'
import { MainContainer } from './styles'
import { Footer } from '../6-Footer/Footer'
import { NavigationBar } from '../0-NavigationBar/NavigationBar'

export const MainScreen = () => {

    return (

        <LangProvider>
            <MainContainer id={'PrincipalScreen'}>
                <NavigationBar />
                <PageSections />
                <Footer />
            </MainContainer>
        </LangProvider >
    )
}
