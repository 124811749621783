export const darkTheme = {
  //Colores Call to action
  primaryColor: '#9f6ed2',
  primaryColorForDarkBG: '#b374f5',
  primaryColorForDarkBG2: '#bb85f2',
  primaryColorD1: '#543276',
  primaryColorD2: '#332244',
  
  primaryColorD1Transparency: 'rgba(84, 50, 118, .85)',

  //Fuentes, botones
  primaryCommon: '#bfc3cc',     //blanco de letra
  contrastCommon:'#282a36',     //negro de letra

  contrastCommonBG: '#e1e5ed',    //blanco
  contrastCommonBG1: '#c8ccd3',   //blanco mas oscuro

  lineColor: '#929594',           //Color de linea para graficos

  secondaryCommonBG: '#353349', //grios violetoso

  
  outsideColorBG: '#1b1d26',          //BG de las secciones
  outsideColorBG2: '#23242d',         //para seccion siguiente a skills
  outsideColorBG2Transparency: 'rgba(35, 36, 45, .7)',
  outsideColorBGTransparency: 'rgba(25, 28, 37, .7)',

  inactive:'#bfc3cca6',
  
  primaryColorBG: '#282a36',
  secondaryColorBG: '#5c4980',
  
  boxShadow: `12px 12px 24px #13141a, -12px -12px 24px #13141a;`,
  buttonShadow: `0 7px 14px 0 rgba(0,0,0,0.2), 0 5px 18px 0 rgba(0,0,0,0.17);`,

  
}