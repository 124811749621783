import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { DivBadgeTechs } from './Experience'
import { AWhite } from '../Global/GlobalStyles'
import { FormattedMessage } from 'react-intl'

const IconMobile = ({ selected }) => (
    <Svg selected={selected} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" width="37" height="43" xmlns="http://www.w3.org/2000/svg">
        <path d="M744 62H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V126c0-35.3-28.7-64-64-64zm-8 824H288V134h448v752zM472 784a40 40 0 1 0 80 0 40 40 0 1 0-80 0z">
        </path>
    </Svg>
)


const IconTablet = ({ selected }) => (
    <Svg selected={selected} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" width="45" height="45" xmlns="http://www.w3.org/2000/svg">
        <path d="M800 64H224c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h576c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zm-8 824H232V136h560v752zM472 784a40 40 0 1 0 80 0 40 40 0 1 0-80 0z">
        </path>
    </Svg>
)

const IconDesktop = ({ selected }) => (
    <Svg selected={selected} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" width="60" height="48" xmlns="http://www.w3.org/2000/svg">
        <path d="M928 140H96c-17.7 0-32 14.3-32 32v496c0 17.7 14.3 32 32 32h380v112H304c-8.8 0-16 7.2-16 16v48c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-48c0-8.8-7.2-16-16-16H548V700h380c17.7 0 32-14.3 32-32V172c0-17.7-14.3-32-32-32zm-40 488H136V212h752v416z">
        </path>
    </Svg>
)

const OPCIONES_DISPOSITIVOS = {
    "DESKTOP": "desktop",
    "TABLET": "tablet",
    "MOBILE": "mobile",
}

const DivDescriptivo = ({ trabajo }) => {
    return <div style={{ width: "100%" }}>
        <HeadTrabajo>
            <PTipo>{trabajo.tipo}</PTipo>
            <PFecha>{trabajo.fecha}</PFecha>
        </HeadTrabajo>

        <H1Nombre>{trabajo.nombre}</H1Nombre>
        <PDescripcion>{trabajo.descripcion}</PDescripcion>
        <PDescripcion><FormattedMessage id={'Techs.usadas.front'} />:</PDescripcion>
        <DivBadgeTechs techs={trabajo.frontendTechs} elementKey={"trabajos-front"} />

        <PDescripcion><FormattedMessage id={'Techs.usadas.back'} />:</PDescripcion>
        <DivBadgeTechs techs={trabajo.backendTechs} elementKey={"trabajos-back"} />
        <AWhite href={"https://www." + trabajo.web} target='_blank'>{trabajo.web}</AWhite>
    </div>
}

const DivVistaDispositivos = ({ trabajo }) => {
    const [dispositivo, setDispositivo] = useState(OPCIONES_DISPOSITIVOS.DESKTOP);

    return (
        <div style={{ width: "100%" }}>
            <ImageContainer size={dispositivo} image={trabajo[dispositivo]}>
                <DivDispositivos>
                    <PSelecciona><FormattedMessage id={'Selecciona.dispositivo'} />:</PSelecciona>

                    <DispositivosContainer>
                        <IconButton onClick={() => setDispositivo(OPCIONES_DISPOSITIVOS.MOBILE)} style={{ marginBottom: "1rem" }}>
                            <IconMobile selected={OPCIONES_DISPOSITIVOS.MOBILE == dispositivo} />
                        </IconButton>

                        <IconButton onClick={() => setDispositivo(OPCIONES_DISPOSITIVOS.TABLET)} style={{ marginBottom: "1rem" }}>
                            <IconTablet selected={OPCIONES_DISPOSITIVOS.TABLET == dispositivo} />
                        </IconButton>

                        <IconButton onClick={() => setDispositivo(OPCIONES_DISPOSITIVOS.DESKTOP)} >
                            <IconDesktop selected={OPCIONES_DISPOSITIVOS.DESKTOP == dispositivo} />
                        </IconButton>
                    </DispositivosContainer>
                </DivDispositivos>
            </ImageContainer>
        </div>
    )
}

export const Trabajo = ({ trabajo, switchearDivs }) => {

    return switchearDivs ?
        <DivTrabajoGrid switchearDivs>
            <DivVistaDispositivos trabajo={trabajo} />
            <DivDescriptivo trabajo={trabajo} />
        </DivTrabajoGrid>
        :
        <DivTrabajoGrid>
            <DivDescriptivo trabajo={trabajo} />
            <DivVistaDispositivos trabajo={trabajo} />
        </DivTrabajoGrid>
}

const DivTrabajoGrid = styled.div`
    column-gap:3rem;
    row-gap:2rem;
    align-items: center;
    display:flex;
    flex-direction:${props => props.switchearDivs ? "column-reverse" : "column"};
    margin-top:14rem;
    width:100%;

    @media(min-width:870px){
        display:grid;
        grid-template-columns: ${props => props.switchearDivs ? "3fr 2fr " : "2fr 3fr"};
    }

`
const Svg = styled.svg`
    fill:${props => props.selected ? props.theme.primaryColor : props.theme.primaryCommon};
    &:hover{
        fill:${props => props.theme.primaryColor};
    }
`
/* DERECHA */
const IconButton = styled.button`
    background-color:transparent;
    border:none;
    cursor:pointer;
`

const PSelecciona = styled.p`
    font-size:1.6rem;
    color:${props => props.theme.primaryCommon};
    margin:1rem 0 ;
    line-height:2rem;
    text-align: center;
`

const DispositivosContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center; 
    justify-content:center;
`

const ImageContainer = styled.div`
    background-image: url(${props => props.image});
    overflow:hidden;
    position:relative;
    display:none;

    ${props => props.size == OPCIONES_DISPOSITIVOS.DESKTOP ? css`
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom:56.25%;
    width: 100%;
    `
        :
        props.size == OPCIONES_DISPOSITIVOS.TABLET ?
            css`
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding-bottom:62.5%;
        width: 50%;
        `
            :
            css`
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding-bottom:63.2%;
        width: 40%;
        `
    }

    transition: all 400ms ease;
    height: 0;
    border-radius:12px;

    margin:0 auto;
    &:hover :first-child{
       opacity:1;
    }

    @media(min-width:680px){
        display:flex;
    }
`

const DivDispositivos = styled.div`
    background-color:green;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    opacity:0;
    transition-duration: 300ms;
    transition-property: opacity;
    transition-timing-function: ease;
    background-color: rgba(27, 29, 38, 0.7);
    // background-color: red;
    backdrop-filter: blur(6px);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-bottom:1rem;
`

/* IZQUIERDA */
const HeadTrabajo = styled.div`
    display:flex;
    align-items: center;
    grid-column:1;
    width:100%;
`

const PTipo = styled.p`
    color:${props => props.theme.primaryColor};
    font-size:1.6rem;
    font-weight: 500;
    min-width: fit-content;
`

const PFecha = styled.p`
    color:#bfc3cca6;
    font-size:1.8rem;
    font-weight: 500;
    margin-left:clamp(1rem, 10% ,2rem);
    min-width: max-content;
`
const H1Nombre = styled.h1`
    color:${props => props.theme.primaryCommon};
    font-size:2.5rem;
    font-weight: 600;
`

const PDescripcion = styled.p`
    font-size:1.6rem;
    color:${props => props.theme.primaryCommon};
    margin:1rem 0 ;
`