import React from 'react';
import styled, { css } from 'styled-components';
import { H2, P } from '../Global/GlobalStyles';

export const Description = styled.div`
  grid-area: desc;
`

export const Title = styled(H2)`
opacity:1;
color:${props => props.theme.primaryColor};

${props => !props.omitAnimation && (css`
    opacity:0;
    ${props => props.show && css`
      opacity:1;
      transition: opacity 1.2s ease-out;
      transition-delay: ${props => (props.timeAnimation - 2.3) + 's'};
      `}
      `)
      }
`

export const Subtitle = styled(P)`
opacity:1;
${props => !props.omitAnimation && (css`
    opacity:0;
    ${props => props.show && css`
      opacity:1;
      transition: opacity 1.2s ease-out;
      transition-delay: ${ props => (props.timeAnimation - 1.9) + 's'};
      `}
      `)
      }
`

export const ItemSvg = styled.svg`
    grid-area: icon;
    height:180px;
    width: 180px;
    margin:0 auto;

    @media (min-width: 700px){
    height:250px;
    width: 250px;
    }
`