import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Link, ListItem } from './styles'


export const NavLink = ({
    sectionName,
    sectionNumber,
    actualSectionNumber,
    setActualSectionNumber,
    onClickEvent
}) => {

    const handleLinkClick = () => {
        onClickEvent()
    }

    const selected = (sectionNumber == actualSectionNumber) ? true : false;

    return (
        <ListItem onClick={handleLinkClick}>
            <Link selected={selected} href={"#" + sectionName}>
                <FormattedMessage id={sectionName} />
            </Link>
        </ListItem>
    )
}
