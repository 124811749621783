import styled from 'styled-components'

export const Grid = styled.div`
    display:grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    margin: 3rem 0;

    grid-template-columns: repeat(auto-fit, minmax(220px, 400px)) ;
    grid-auto-rows: auto auto;
    grid-template-areas: 'icon' 'desc';

    background-color: ${props => props.theme.outsideColorBG2};
    border-radius: 20px;
    min-width: 290px;
    width: 70%;
    max-width: 500px;
    padding:20px;

    box-shadow: 4px 8px 21px 0px rgba(17,18,23,0.40);
    -webkit-box-shadow: 4px 8px 21px 0px rgba(17,18,23,0.40);
    -moz-box-shadow: 4px 8px 21px 0px rgba(17,18,23,0.40);

    @media (min-width: 950px) {
        grid-template-columns: 250px 200px 250px;
        grid-template-rows: 250px; 
        grid-template-areas: ${props => `'${props.area}'`};
        column-gap: 20px;
        min-width: 400px;
        max-width: 900px;
        padding:20px;
        width: 90%;
        margin: 3.5rem 40px;
        text-align: left;
    }
`