import styled, { css } from "styled-components"

export const P = styled.p`
    font-size       : 1.6rem;
    font-weight     : 400;
    line-height     : 3rem;

    
    ${props =>
        props.margin && css`margin:${props.margin};`
    }  

    ${props =>
        (props.color == 'contrast') ? css`color:${props.theme.contrastCommon};`
            : css`color:${props.theme.primaryCommon};`
    }    
`

export const B = styled.b`

    ${props => props.color ? css`color:${props.theme[props.color]};` :
        props.primary ? css`color:${props.theme.primaryColorD1};` :
            props.lightPrimary ? css`color:${props.theme.primaryColor};` :
                props.black ? css`color:${props.theme.primaryCommon};` :
                    css`color:${props.theme.primaryColor};`
    }                               
    font-size: 110%;
`

export const A = styled.a`
    font-size: 1.3rem;
    font-weight: 400;
    text-size-adjust: 20px;
    margin-right:40px;
    letter-spacing:3.1px;
    cursor: pointer;
    color:${props => props.theme.contrastCommon};

    &:hover {
        text-decoration: 2px underline black;
    }
`

export const AWhite = styled.a`
    font-size: 1.5rem;
    font-weight: 400;
    text-size-adjust: 20px;
    cursor: pointer;
    color:${props => props.theme.primaryColor};
    text-decoration: none;

    &:hover {
        text-decoration: 1px underline;
    }
`

export const H2 = styled.h2`
    font-size: 2.5rem;
    color: ${props => props.theme.primaryCommon};
    @media (min-width: 700px){
        font-size: 2.9rem;
    }
`

export const Button = styled.button`
    width: ${props => props.width};
    height: ${props => props.height};
    padding:14px;
    border-radius: 16px;
    font-size:1.4rem;
    display:flex;
    justify-content:center;
    border: none;
    color: ${props => props.theme.primaryCommon};
    transition:all 400ms ease-out;
    cursor:pointer;
    background-color: ${props => props.theme.primaryColorD1};
    box-shadow: ${props => props.theme.buttonShadow};
    text-decoration: none;

    ${props => props.margin && css`margin:${props.margin};`}

    &:hover {
        background-color: ${props => props.theme.primaryColorD1Transparency};
        transition:all 100ms ease-in;
    }
`

export const Section = styled.section`
    width:100%;
    background-color: ${props => props.theme[props.bgColor]};
    display:flex;
    justify-content: center;
    overflow: hidden;
`

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px 90px 20px;
    width: 100%;
    max-width: 1200px;
    height: auto;
    @media (min-width: 950px) {
         padding: 110px 20px 110px 20px;    
        }
`