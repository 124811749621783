import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from './styles';
import { Item1 } from './Item1';
import { Item2 } from './Item2';
import { Item3 } from './Item3';
import { SectionTitle } from '../Component/SectionTitle';
import { Section, SectionContainer } from '../Global/GlobalStyles';
import { ContextConsumer } from '../Context/ContextConsumer';


export const ServicesSection = ({ id, title, isSmall }) => {

  const {showServiceItems} = ContextConsumer();

  return (
    <Section id={id} bgColor={'outsideColorBG'}>
      <SectionContainer>

        <SectionTitle id={title} bgColor={'outsideColorBG'}>
          <FormattedMessage id="Servicios"/>
        </SectionTitle>

        <Grid area={'desc desc icon'}>
          <Item1 omitAnimation={isSmall} show={showServiceItems[0]} />
        </Grid>

        <Grid area={'icon desc desc'}>
          <Item2 omitAnimation={isSmall} show={showServiceItems[1]} />
        </Grid>

        <Grid area={'desc desc icon'}>
          <Item3 omitAnimation={isSmall} show={showServiceItems[2]} />
        </Grid>

      </SectionContainer>
    </Section>
  )
}