import React from 'react'
import { FormattedMessage } from 'react-intl';
import { SectionTitle } from '../Component/SectionTitle'
import { Experience } from './Experience'
import { Section, SectionContainer } from '../Global/GlobalStyles';
import styled from 'styled-components';
import { Trabajo } from './Trabajo';

export const ExpecienciaSection = ({ id, title, showSection, isSmall }) => {

    const experienciasLaborales = [
        {
            nombre: "ESPIN LABS",
            fecha: <FormattedMessage id={'FECHA_ESPINLABS'} />,
            descripcion: <FormattedMessage id={'DESCRIPCION_ESPINLABS'} />,
            techs: ["SCRUM", "React", "HTML", "SASS", "Javascript", "Redux"],
            rol: "Frontend Dev",
        },
        {
            nombre: "NK Software Studio",
            fecha: <FormattedMessage id={'FECHA_NK'} />,
            descripcion: <FormattedMessage id={'DESCRIPCION_NK'} />,
            techs: ["SCRUM", "React", "HTML", "CSS", "Javascript", "Redux"],
            rol: "Frontend Dev",
        },
        {
            nombre: "Freelance",
            fecha: <FormattedMessage id={'FECHA_FREELANCE'} />,
            descripcion: <FormattedMessage id={'DESCRIPCION_FREELANCE'} />,
            techs: ["HTML", "CSS", "Javascript"],
            rol: "Frontend Dev",
        },
    ]

    const trabajosRealizados = [
        {
            tipo: "Web App",
            fecha: <FormattedMessage id={'FECHA_FIGURE'} />,
            nombre: "Figure Estetica",
            descripcion: <FormattedMessage id={'DESCRIPCION_FIGURE'} />,
            web: "figurestetica.com.ar",
            frontendTechs: ["React", "HTML", "CSS", "Javascript", "Redux"],
            backendTechs: ["Node", "Express", "MySQL", "Sequelize"],
            desktop: require("../assets/images/figure-desktop.webp"),
            tablet: require("../assets/images/figure-tablet.webp"),
            mobile: require("../assets/images/figure-mobile.webp"),
        },
        {
            tipo: "Web App",
            fecha: <FormattedMessage id={'FECHA_VIKA'} />,
            nombre: "Vika Financiera",
            descripcion: <FormattedMessage id={'DESCRIPCION_VIKA'} />,
            web: "vikafinanciera.com",
            frontendTechs: ["React", "HTML", "CSS", "Javascript"],
            backendTechs: ["Node", "Express", "MySQL", "Sequelize"],
            desktop: require("../assets/images/vika-desktop.webp"),
            tablet: require("../assets/images/vika-tablet.webp"),
            mobile: require("../assets/images/vika-mobile.webp"),
        }
    ]

    return (
        <Section id={id} bgColor={'outsideColorBG'}>
            <SectionContainer>
                <SectionTitle id={title} bgColor={'outsideColorBG'}>
                    <FormattedMessage id="Experiencia" />
                </SectionTitle>

                <UlExperienciasContainer>
                    {experienciasLaborales.map((ctExp, index) =>
                        <React.Fragment key={"experiencias-" + ctExp.nombre + index}>
                            <Experience startOpened={index==0} experiencia={ctExp}></Experience>
                        </React.Fragment>
                    )}
                </UlExperienciasContainer>

                <UlTrabajosContainer>
                    {trabajosRealizados.map((ctTrabajo, index) =>
                        <React.Fragment key={"trabajos-" +ctTrabajo.nombre + index}>
                            <Trabajo trabajo={ctTrabajo} switchearDivs={index % 2 != 0}></Trabajo>
                        </React.Fragment>
                    )}
                </UlTrabajosContainer>
            </SectionContainer>
        </Section>
    )
}

const UlTrabajosContainer = styled.div`
    width:100%;
    margin:0 2rem;
    padding:0 2rem;
`

const UlExperienciasContainer = styled.div`
    width:100%;
    margin:2rem 2rem 0;
    padding:0 2rem;
`
