import { ID_SECTIONS, OBSERVABLES_ITERADAS, OBSERVABLES_UNICA_VEZ, TITLE_SECTIONS } from '../Global/appConfig'

export const handleEntiresIntersected = (entriesIntersect, states) => {
    const {
        setActualSectionNumber, 
        showSectionTitle,
        setShowSectionTitle,
        showServiceItems,
        setShowServiceItems,
        setShowExperienceSection
    } = states;

    let newShowServiceItems = [...showServiceItems];
    let newShowSectionName = [...showSectionTitle];
    let newActualSection;
    let newShowExperienceSection;


    // console.log(entriesIntersect);

    entriesIntersect.forEach(
        (element) => {
            const currentId = element.target.id;
            const currentState = element.isIntersecting;

            if (OBSERVABLES_UNICA_VEZ.indexOf(currentId) != -1 && currentState) {
                newShowServiceItems[OBSERVABLES_UNICA_VEZ.indexOf(currentId)] = true;
                return setShowServiceItems(newShowServiceItems);
            }

            if (OBSERVABLES_ITERADAS.indexOf(currentId) != -1) {
                newShowSectionName[OBSERVABLES_ITERADAS.indexOf(currentId)] = currentState;
                return setShowSectionTitle(newShowSectionName);
            }

            if (ID_SECTIONS.indexOf(currentId) != -1 && currentState) {
                // console.log("currentId", currentId);

                newActualSection = ID_SECTIONS.indexOf(currentId);
                // console.log("newActualSection", newActualSection)

                if (currentId == 'Experiencia' && currentState) {
                    newShowExperienceSection = true;
                    setShowExperienceSection(newShowExperienceSection);
                }
                return setActualSectionNumber(newActualSection);
            }
        }
    );
}