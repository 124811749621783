import React, { useEffect, useState } from 'react'
import { PagesContainer } from './styles'
import { ID_SECTIONS, OBSERVABLES, TITLE_SECTIONS } from '../Global/appConfig'
import { InitialSection } from '../1-Inicio/InitialSection'
import { useObserver } from '../observers/sectionObserver'
import { ContextConsumer } from '../Context/ContextConsumer'
import { SkillsSection } from '../3-Skills/SkillsSection'
import { ExpecienciaSection } from '../4-Experiencia/ExperienciaSection'
import { ServicesSection } from '../2-Services/ServicesSection'
import { useResize } from '../observers/sizeObserver'
import { ContactoSection } from '../5-Contacto/ContactoSection'
import { handleEntiresIntersected } from './handleEntiresIntersected'



export const PageSections = () => {

    const [observer, setElementsIntersect, entriesIntersect] = useObserver({ threshold: 0.4, root: null })
    const [observerr, setElementsResize, entriesResize] = useResize()

    const [isSmall, setIsSmall] = useState(true)

    const states = ContextConsumer();

    const {
        showServiceItems,
        showExperienceSection,
    } = states;


    useEffect(() => {
        //Iniciamos el size-observer 
        setElementsResize([document.getElementById('PrincipalScreen')]);
    }, [setElementsResize]);

    useEffect(() => {
        //Analizamos RESIZE
        entriesResize[0] && (
            entriesResize[0].contentRect.width > 787 ? setIsSmall(false) : setIsSmall(true)
        )
    }, [entriesResize]);

    useEffect(() => {
        //Iniciamos el intersection-observer 
        setElementsIntersect(OBSERVABLES.map(elementName => document.getElementById(elementName)));
    }, [setElementsIntersect]);

    useEffect(() => {
        //Analizamos el INTERSECTION
        handleEntiresIntersected(entriesIntersect, states);
    }, [entriesIntersect]);

    return (
        <PagesContainer>

            <InitialSection
                id={ID_SECTIONS[0]}
                title={TITLE_SECTIONS[0]}
            />
            <ServicesSection
                id={ID_SECTIONS[1]}
                title={TITLE_SECTIONS[1]}
                showSection={showServiceItems}
                isSmall={isSmall}
            />
            <SkillsSection
                id={ID_SECTIONS[2]}
                title={TITLE_SECTIONS[2]}
            />

            <ExpecienciaSection
                id={ID_SECTIONS[3]}
                title={TITLE_SECTIONS[3]}
                showSection={showExperienceSection}
                isSmall={isSmall}
            />

            <ContactoSection
                id={ID_SECTIONS[4]}
                title={TITLE_SECTIONS[4]}
            />

        </PagesContainer>
    )
}

