import React from 'react';
import styled from 'styled-components';
import { zIndex } from '../Global/appConfig';
import { FormattedMessage } from 'react-intl';

export const AlternativeData = () => {
    return (
        <Container>
            <Data
                title={<FormattedMessage id="NOMBRE" />}
                subtitle='Ariel Claudio Sitta'
            />

            <Data
                title={<FormattedMessage id="ROL" />}
                subtitle={<FormattedMessage id="Desarrollador.Frontend" />}
            />

            <Data
                title='EMAIL'
                subtitle='info@arsitta.com.ar'
            />

            <Data
                title={<FormattedMessage id="TELEFONO" />}
                subtitle='(+54 9) 11 2512-0785'
            />
        </Container>
    )
}

export const Data = ({ title, subtitle }) => {
    return (
        <DataContainer>
            <Title>{title}</Title>
            <P>{subtitle}</P>
        </DataContainer>
    )
}



export const Container = styled.div`
    position:relative;
    display:none;

    margin: 70px 0 30px 30px;
    z-index: ${zIndex.arriba};

    @media (min-width: 1360px) {
        display:block;
    }

`

export const DataContainer = styled.div`
    position:relative;
    margin: 0 0 30px 30px;
`

export const Title = styled.p`
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing:3.1px;
    color: ${props => props.theme.primaryColor};
`

export const P = styled.p`
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2rem;
    color: ${props => props.theme.primaryCommon};
`