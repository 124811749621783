import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 5rem;
    color: ${props => props.theme.primaryCommon};
`

export const SubSection = styled.div`   
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;

    & div {
        margin-bottom: 15px;
    }

    @media (min-width: 700px) {
        grid-template-columns: 1fr 1fr;
        
        & div {
        margin-bottom: 0;
        }
    }
`

export const Div = styled.div`   
     background-color: ${props => props.theme.outsideColorBG};
     padding:40px;
     border-radius: 30px;
`
