import React from 'react';
import styled, {css} from 'styled-components';
import { keyframes } from 'styled-components';
import { zIndex } from '../Global/appConfig';

export const BottomNavIndicator = ({moveScrollIndicator, actualSectionNumber}) => {
  return (
    <BottomContainer>

      <NavIndicator>
        <Svg> 
          <Text y='150'>0{actualSectionNumber+1}</Text>
        </Svg>
      </NavIndicator>

      <ScrollContainer moveScrollIndicator={moveScrollIndicator}>
        <Circle />
      </ScrollContainer>

    </BottomContainer>
  )
}

const BottomContainer = styled.div`
  position:absolute;
  bottom:0;
  margin: 90px 0px 0 50px;
  z-index: ${zIndex.modal};
  display: none;

  @media (min-width: 1360px) and (min-height:700px) {
    display: flex;
  }
`

const NavIndicator = styled.h3`
  height: 200px;
`

const Svg = styled.svg`
  font-size: 12rem;
  width  : auto;
  height : 100%;
  text-align:center;

  @media (min-width: 1550px) {
    font-size: 15rem;
  }
`

const Text = styled.text`
  fill            : none;
  stroke          : ${props=>props.theme.primaryColor};
  stroke-width    : 4px;
  stroke-linejoin : round;
`
const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top:8px;
  width: 38px;
  height: 60px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  position:absolute;
  right:12px;
  bottom:50px;
  outline: 8px solid ${props => props.theme.primaryColorBG};
  border: 3px solid ${props=>props.theme.primaryColor};
  background-color: ${props => props.theme.primaryColorBG};

  transition-property: transform, background-color;
  transition-duration: 300ms;
  transition-timing-function: ease-out;

  @media (min-width: 1550px) {
    right:-48px;
  }
  
  ${props => props.moveScrollIndicator && css`
    transform: translateX(-50px);
    outline: none;
    background-color: transparent;

    transition-property: transform, background-color;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    transition-delay: 100ms;
  `}
`


const scroll = keyframes`
0% {
  opacity: 1;
  transform: translateY(0);
}
100%{
  opacity: 0;
  transform: translateY(25px);
}
`

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${props=>props.theme.primaryColor};
  border-radius: 50%;
  animation-name: ${scroll};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`