import React, { createContext, useState } from 'react'
import { TITLE_SECTIONS } from '../Global/appConfig';

export const AppContext = createContext();

export const ContextProvider = ({ children }) => {

    const [actualSectionNumber, setActualSectionNumber] = useState(0);
    const [lang, setLang] = useState('es-AR');

    //Estado para activar el show efect de los TITULOS
    const [showSectionTitle, setShowSectionTitle] = useState(TITLE_SECTIONS.map(e => false));

    //Estado para activar el show efect de los ITEMS de la seccion SERVICIOS
    const [showServiceItems, setShowServiceItems] = useState([false, false, false]);

    //Estado para activar el show efect de la section EXPERIENCIA
    const [showExperienceSection, setShowExperienceSection] = useState(false);

    return (
        <AppContext.Provider value={
            {
                actualSectionNumber,
                setActualSectionNumber,
                
                lang,
                setLang,

                showSectionTitle,
                setShowSectionTitle,

                showServiceItems,
                setShowServiceItems,
                
                showExperienceSection,
                setShowExperienceSection
            }
        }>
            {children}
        </AppContext.Provider>
    )
}
