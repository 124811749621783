import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';

export const ProgressBar = ({ actualSectionName, actualSectionNumber, numberOfSections }) => {
    const percentScrolled = (actualSectionNumber == '0') ? '5%' : ((actualSectionNumber / (numberOfSections - 1) * 100) + '%');
    const actualSection = `0${actualSectionNumber + 1} - `;

    return (
        <ComponentContainer>
            
            <ActualSection key={actualSection}>
                {actualSection + actualSectionName}
                {/* <FormattedMessage id={actualSectionName} /> */}
            </ActualSection>

            <ProgressLineaContainer>
                <ProgressLinea size={percentScrolled} />
            </ProgressLineaContainer>

            <NumberOfSections>{"0" + (numberOfSections)}</NumberOfSections>

        </ComponentContainer>
    )
}

const changeSection = keyframes`
    0% {opacity: 0;}
    100%{opacity: 1;}
`

const ComponentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: end;
    align-items: center;
`

const ProgressLineaContainer = styled.div`
    width: 70px;
    margin: 0 10px;
    background-color: ${props => props.theme.contrastCommonBG1};
`

const ProgressLinea = styled.div`
    width: ${props => props.size};
    height: 3px;
    background-color: ${props => props.theme.primaryColorD1};
    transition: width 500ms ease-out;
`

const NumberOfSections = styled.h3`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${props => props.theme.contrastCommonBG1};
`

const ActualSection = styled.h3`
    animation: ${changeSection} 500ms linear;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${props => props.theme.primaryColorForDarkBG};
`

