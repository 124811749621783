import styled from 'styled-components';

export const MainContainer = styled.div`
    position: relative;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:55px auto;
    grid-template-areas: 'navbar'
                         'body'
                         'footer';

  @media (min-width: 1024px) {
      grid-template-columns: 90px 1fr;
      grid-template-rows: auto;
      grid-template-areas: 'navbar body'
                           'navbar footer';
  }
  
  @media (min-width: 1360px) {
      grid-template-columns: 320px 1fr;
      grid-template-rows: auto;
      grid-template-areas: 'navbar body'
                           'navbar footer'
  }

  @media (min-width: 1550px) {
      grid-template-columns: 380px 1fr;
      grid-template-rows: auto;
      grid-template-areas: 'navbar body'
                           'navbar footer';
  }
`

