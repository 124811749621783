import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
`

export const Div = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding:20px;
    grid-area: footer;
    background-color: ${prop => prop.theme.outsideColorBG};
`

export const Svg = styled.svg`
    width:25px;
    height:25px;
    margin: 0 8px 0 8px;
    animation: ${rotate} 5s linear infinite;

    
    & path {
        fill:${props=>props.theme.primaryColor};
    }
`

