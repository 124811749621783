import React from 'react'
import styled, { css } from 'styled-components';

import { ContextConsumer } from '../Context/ContextConsumer'
import { TITLE_SECTIONS, zIndex } from '../Global/appConfig';


export const SectionTitle = ({ children, id, bgColor }) => {
  const inicio = `<`;
  const fin = `/>`
  const { showSectionTitle } = ContextConsumer();
  const selected = (showSectionTitle[TITLE_SECTIONS.indexOf(id)] == true ? true : false);

  return (
    <TitleContainer>
      <InicioContainer selected={selected} bgColor={bgColor}><Title color={'primary'}>{inicio}</Title></InicioContainer>
      <Title id={id}>{children}</Title>
      <FinContainer selected={selected} bgColor={bgColor}><Title color={'primary'}>{fin}</Title></FinContainer>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
position:relative;
`

const Title = styled.h1`
    position: relative;
    width:min-content;
    font-size: 3.7rem;
    color: ${props => (props.color=='primary') ? props.theme.primaryColor : props.theme.primaryCommon};

    z-index: ${zIndex.arriba};

    @media (min-width:700px){
      font-size: 5rem;
      text-align: left;
    }
`

const InicioContainer = styled.div`
    display: flex;
    justify-content: right;
    width:100%;
    background-color: ${props=>props.theme[props.bgColor]};
    position: absolute;
    right:50%;
    z-index: ${zIndex.sobretodo};
    transition: transform 0.6s ease-out;
    ${props=>props.selected && css`
      transform: translateX(-50%);  
      transition-delay: 200ms;
      `}
`

const FinContainer = styled.div`
  display: flex;
  justify-content: left;
  width:100%;
  background-color: ${props=>props.theme[props.bgColor]};
  position: absolute;
  top:0;
  left:50%;
  z-index: ${zIndex.sobretodo};
  transition: transform 0.6s ease-out;
  ${props=>props.selected && css`
       transform: translateX(50%);  
       transition-delay: 200ms;
  `} 

`

