import styled, { css } from 'styled-components';
import { zIndex } from '../Global/appConfig';

export const NavigationBarComponent = styled.div`
    position: sticky;    
    top: 0;
    grid-area: navbar;
    z-index: ${zIndex.modal};

    @media (min-width: 1024px) {
        height: 100vh;
        
    }   
`

export const TopNavBar = styled.div`
    position: relative;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    z-index: ${zIndex.modal}; 
    
    @media (min-width: 1024px) and (max-width: 1360px) {
        padding: 20px 0;
        justify-content: center;

        div:nth-child(2)  {
            display:none;
        }
    }    
    
    @media (min-width: 1360px) {

        padding: 20px 30px;
        flex-direction: column;
        align-items: start;

        &:nth-child(1) {
            height: 170px;
        }
    }

`
export const TopNavBarBG = styled.div`
    position: absolute;
    left: 0;
    top:0;
    z-index: ${zIndex.subtodo};
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.primaryColorBG};
`

export const ModalNavMenu = styled.div`
    height: 100vh;

    display:flex;
    flex-direction: column;
    justify-content: space-between;

    position: absolute;
    left: 0;
    top: 0;
    right: 100%;
    
    background-color: ${props => props.theme.secondaryColorBG};
    overflow: hidden;
    transition: right 300ms linear;
    z-index: ${zIndex.sobretodo};

    ${props => (props.show) &&
        css`
            width: auto;
            right: 0;
            @media (min-width: 768px) and (max-width: 1550px){
                right: calc(100% - 320px);
            }
        `
    }
    
    @media (min-width: 1360px) {
            padding-top: 135px;
    }
 `

export const Hr = styled.hr`
    margin:2rem 0;
`

export const SvgMenuIcon = styled.svg`
    stroke: white;
    fill:none;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    height:3.5rem;
    width:3.5rem;
    grid-template-areas: button;
    transition:transform 300ms ease-out;
    cursor:pointer;

    & line {
        transition:transform 300ms ease-out;
    }

    &:hover {
        stroke: ${props=> props.theme.primaryColor };
    }

    & line:nth-child(1){
            transform-origin: center;
            transition:transform 300ms ease-out;
        }

        & line:nth-child(2){
            transform-origin: 5px 9px;
            transition:transform 300ms ease-out;
        }

        & line:nth-child(3){
            transform-origin: 5px 15px;
            transition:transform 300ms ease-out;
        }

    
    ${props=>props.show && css`
        & line:nth-child(1){
            transform: scale(0);
        }

        & line:nth-child(2){
            transform: rotateZ(45deg);
        }

        & line:nth-child(3){
            transform: rotateZ(-45deg);
        }
    `}

`

export const ListItem = styled.li`
position: relative;
list-style: none;
margin-bottom: 10px;
width: min-content;

`
export const Link = styled.a`
text-decoration: none;
color:${props => props.theme.primaryCommon};
font-weight: 700;
font-size: 2.9rem;
transition: right 400ms ease-in;

&::after{
        content: " ";
        display: block;
        position: absolute;
        z-index: -1;

        height: 20px;
        left:0;
        right:100%;
        
        margin-top: -20px; 
        
        background-color: ${props => props.theme.primaryColorD2};
        transition: right 200ms ease-in;

        ${props => (props.changed==true) &&
        css`
            right:0;      
            `
         } 
}

&:hover::after {
            right:0;
        }

`
export const Lista = styled.ul`
margin: 110px 70px;
@media (min-width:1360px) {
    transform:translateY(-60px);
}
`


export const LinkLang = styled.div`
font-weight: 700;
font-size: 2rem;
cursor:pointer;

&::after{
    content: " ";
        display: block;
        position: absolute;
        z-index: -1;

        height: 20px;
        left:0;
        right:100%;
        
        margin-top: -20px; 
        
        background-color: ${props => props.theme.primaryColorD2};
        transition: right 200ms ease-in;

    ${props => (props.selected) &&
        css`
            right:0;      
            `
         }     
}

&:hover::after {
            right:0;
        }

`
export const LinkContainer = styled.div`
width: min-content;
position:relative;
margin:0 10px;
`

export const LangSwitch = styled.li`
    display: flex;
    flex-direction: column;
    color:${props => props.theme.primaryCommon};
    font-weight: 700;
    font-size: 2rem;
`

export const DivRow = styled.div`
    display: flex;
    flex-direction: row;
`