import React from 'react'
import { ThemeProvider } from 'styled-components'
import { darkTheme } from './darkTheme'

export const AppThemeProvider = ({children}) => {
    return (
        <ThemeProvider theme={darkTheme}>
            {children}
        </ThemeProvider>
    )
}
