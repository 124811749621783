import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Description, ItemSvg, Subtitle, Title } from './itemStyles';


const timeDraw = 2.4;
const delayInicial = 0.1;
const timeAnimation = timeDraw + delayInicial;

export const Item1 = ({ show, omitAnimation }) => {

  return (
    <>
      <Svg omitAnimation={omitAnimation} show={show} xmlns="http://www.w3.org/2000/svg" viewBox="260 150 100 450">
        <Path white d="M276.16,252.78H138.65s-21.13.52-21.13,21.13v238.7S117,530,134.87,530H477.26s21.46-1.57,21.46-21.46V272.61s-2.09-19.6-19.6-19.6c-12.44,0-106.37-.11-159.72-.18" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M326.48,231.91c-21.39-11.48-25.05-12-25.05-12" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="312.46" y1="228.07" x2="269.22" y2="307.3" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path white d="M472.57,278.35" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline points="164.35 282.26 164.35 373.83 202.3 373.83 202.3 282.26" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline points="469.56 303.39 316.96 303.39 316.96 342.91 469.56 342.91" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="367.83" y1="452.48" x2="469.57" y2="452.48" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="247.7" y1="452.48" x2="147.91" y2="452.48" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="351.78" y1="321" x2="351.78" y2="340.96" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="334.17" y1="328.04" x2="334.17" y2="340.96" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="386.22" y1="321" x2="386.22" y2="340.96" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="421.83" y1="321" x2="421.83" y2="340.96" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="455.48" y1="321" x2="455.48" y2="340.96" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="439.04" y1="326.09" x2="439.04" y2="339.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="403.83" y1="326.09" x2="403.83" y2="339.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="369.39" y1="326.09" x2="369.39" y2="339.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M228.13,382.43h77.48s11.15.4,11.15,11.16-10.17,10.17-10.17,10.17-10.25-.83-10.25,10.25,10.57,10.56,10.57,10.56,9.48.07,9.48,9.49v9.42" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Circle white cx="235.7" cy="214.65" r="6.26" style={{ fill: "#9c9da0" }} />
        <Circle white cx="421.83" cy="206.87" r="6.26" style={{ fill: "#9c9da0" }} />
        <Circle white cx="371.87" cy="228.07" r="6.26" style={{ fill: "#9c9da0" }} />
        <Line white x1="143.61" y1="548.09" x2="193.43" y2="548.09" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="421.83" y1="548.09" x2="472.57" y2="548.09" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path white d="M247.85,530.74s-18.36,1.3-18.36,18.36,17,17,17,17H370.33s16.77-.11,16.77-16.77-18-18-18-18" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Circle white cx="164.54" cy="212.61" r="14.09" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline white points="308.12 278.35 472.56 278.35 472.56 476.74 143.61 476.74 143.61 278.35 262.17 278.35" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M308,200.61l-8.61,15.69L231.52,339.91v36l27.65-14.61L338,216.78s6.26-12-7.83-21.39S308,200.61,308,200.61Z" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Circle white cx="464.48" cy="208.11" r="18" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
      </Svg>


      <Description id='Item1'>
        <Title
          timeAnimation={timeAnimation}
          omitAnimation={omitAnimation}
          show={show}
        >
          <FormattedMessage id="Sitios.web.personalizados" />
        </Title>
        <Subtitle
          timeAnimation={timeAnimation}
          omitAnimation={omitAnimation}
          show={show}>
          <FormattedMessage id="Proyectos.realizados.de.acuerdo" />
        </Subtitle>
      </Description>
    </>

  )
}

const Line = styled.line`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:105px; 
`

const Polyline = styled.polyline`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
    stroke-dasharray:1050px; 
`

const Path = styled.path`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:1250px;
`

const Circle = styled.circle`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
`


const Svg = styled(ItemSvg)`
  ${props => !props.omitAnimation && (
    css`
            & Path { 
              stroke-dashoffset:1250px;
            }
            & Circle {
              opacity: 0;
            }
            & Polyline {
              stroke-dashoffset:1050px;
            }
            & Line {
              stroke-dashoffset:105px; 
            }

              ${props => props.show && css`
                  & Path, Line, Polyline
                  {
                    stroke-dashoffset:0;
                    transition: all ${timeDraw + 's'} ease-out;
                    transition-delay: ${delayInicial + 's'};
                  }

                  & Circle{
                    opacity:1;
                    transition: all 500ms ease-out;
                    transition-delay: ${(delayInicial + 0.3) + 's'};
                  }
                  `
      }
            `
  )
  }
`

