import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Description, ItemSvg, Subtitle, Title } from './itemStyles';

const timeDraw = 2.4;
const delayInicial = 0.3;
const timeAnimation = timeDraw + delayInicial;

export const Item3 = ({ show, omitAnimation }) => {
  return (
    <>
      <Svg omitAnimation={omitAnimation} show={show} xmlns="http://www.w3.org/2000/svg" viewBox="260 75 100 460">
        <Line white x1="473.38" y1="318.75" x2="473.38" y2="465.13" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="151.04" y1="319.24" x2="151.04" y2="464.02" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M453.92,177.39l35.6,89.22V295.3s-.81,21.79-24.95,21.79-26.29-21.52-26.29-21.52V270.39l-15.8-93" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M438.28,295.63s-.79,21.78-24.13,21.78-25.44-21.52-25.44-21.52V270.71l-10.43-93.32" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M338.48,294.43s-.81,21.78-24.81,21.78-26.15-21.52-26.15-21.52V177.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M389.22,294.43s-.8,21.78-24.7,21.78-26-21.52-26-21.52V269.51L337.43,179" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M170.67,178.08,135.06,267.3V296s.81,21.78,24.95,21.78,26.3-21.52,26.3-21.52V271.08l15.8-93" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M186.31,296.31s.78,21.78,24.13,21.78,25.43-21.52,25.43-21.52V271.4l10.44-93.32" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M235.37,295.11s.8,21.79,24.7,21.79,26-21.53,26-21.53V270.2l1-90.56" style={{ fill: "none", strokeMiterlimit: 10, strokeWidth: "9px" }} />
        < Path white d="M168.26,177.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path white d="M164.18,177.39l294.62.1Z" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="509.09" y1="500.61" x2="117" y2="500.61" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline white points="168.59 174.19 168.59 151.5 250.76 151.5" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline white points="252.65 174.19 252.65 118.17 370.56 118.17 370.56 174.19" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline white points="372.33 151.5 454.37 151.5 454.37 177.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="154.57" y1="441.39" x2="187.96" y2="441.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="272.74" y1="441.39" x2="469.96" y2="441.39" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="138.13" y1="272.09" x2="485.87" y2="272.09" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="297.64" y1="385.3" x2="360.39" y2="385.3" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="329.01" y1="354" x2="329.01" y2="417.13" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="418.3" y1="354" x2="418.3" y2="417.13" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line white x1="387.59" y1="385.57" x2="449.8" y2="385.57" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Rect white x="295.83" y="351.72" width="67.5" height="66.33" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Rect white x="385.43" y="351.72" width="65.93" height="66.33" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Path d="M189,462.07V360.33s.2-5.58,5.58-5.58H265.5s4.55.2,4.55,4.55V463" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="202.11" y1="408.9" x2="232.57" y2="380.87" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Line x1="225.26" y1="413.74" x2="267" y2="374.61" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Polyline white points="133.44 497.48 133.44 465.13 489.52 465.13 489.52 497.48" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Punto white cx="135.59" cy="395.76" r="5.74" />
        <Punto white cx="196.37" cy="125.09" r="5.74" />
        <Punto white cx="132.39" cy="212.74" r="5.74" />
        <Punto white cx="498.91" cy="200.87" r="5.74" />
        <Punto white cx="416.74" cy="128.48" r="5.74" />
        <Punto white cx="491.61" cy="357.46" r="5.74" />
        <Circle white cx="492.15" cy="146.18" r="18.46" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
        <Circle white cx="134.39" cy="173.72" r="18.46" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "9px" }} />
      </Svg>

      <Description id='Item3'>
        <Title
          timeAnimation={timeAnimation}
          omitAnimation={omitAnimation}
          show={show}>
          E-commerce
        </Title>
        <Subtitle
          timeAnimation={timeAnimation}
          omitAnimation={omitAnimation}
          show={show}>
          <FormattedMessage id="Presencia.digital.para" />
        </Subtitle>
      </Description>

    </>


  )
}

const Line = styled.line`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:400px;
`

const Polyline = styled.polyline`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
    stroke-dasharray:450px; 
`

const Path = styled.path`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:600px; 
`

const Rect = styled.rect`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
  stroke-dasharray:270px; 
`

const Circle = styled.circle`
  ${props => props.white ? css`stroke:${props.theme.primaryCommon};` : css`stroke:${props => props.theme.primaryColor};`} 
`

const Punto = styled.circle`
  ${props => props.white ? css`fill:${props.theme.primaryCommon};` : css`fill:${props => props.theme.primaryColor};`} 
`

const Svg = styled(ItemSvg)`
    ${props => !props.omitAnimation && (
    css`
                & Punto {
                  opacity: 0;
                }
    
                & Circle {
                  opacity: 0;
                }

                & Rect {
                  stroke-dashoffset:270px;
                }

                & Path {
                  stroke-dashoffset:600px;
                }

                & Polyline {
                  stroke-dashoffset:450px; 
                }

                & Line {
                  stroke-dashoffset:400px;  
                }

                ${props => props.show && css`
                  & Path, Line, Polyline
                  {
                    stroke-dashoffset:0;
                    transition: all ${timeDraw + 's'} ease-out;
                    transition-delay: ${delayInicial + 's'};
                  }

                  & Circle{
                    opacity:1;
                    transition: all 500ms ease-out;
                    transition-delay: ${(delayInicial + 0.3) + 's'};
                  }
                  `
      } 
                `
  )
  }
`