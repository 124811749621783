import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Data } from '../0-NavigationBar/AlternativeData';


export const ContactData = ({ textCopy = false, ancor = false, title, subtitle, children }) => {

    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState()

    function showAlert(text) {
        setShowMessage(true);
        setMessage(text);

        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    }

    return (
        <>
            {textCopy ?
                <CopyToClipboard
                    text={textCopy}
                    onCopy={() => showAlert('Copiado')}
                >

                    <Container pointer>
                        {children}
                        <Data
                            title={title}
                            subtitle={subtitle}
                        />
                        <CopyContainer>

                            <MessageContainer showMessage={showMessage}>
                                {message}
                            </MessageContainer>


                            <CopySvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.8 286.94">
                                <path d="M286.76,172.28c0,28.33.08,56.65,0,85-.07,14.59-8.2,25.13-21.59,28.73a35.61,35.61,0,0,1-9.42.76c-55.66,0-111.31-.3-167,.19-20.4.18-31.66-15.65-31.51-31.48.53-56,.2-112,.21-168,0-17.89,12.18-30,30.09-30H257c17.4,0,29.6,11.85,29.7,29.34C286.88,115.29,286.76,143.78,286.76,172.28ZM86.38,172c0,27.16.07,54.31-.09,81.47,0,3.71,1,4.8,4.75,4.79q81.21-.18,162.44,0c3.76,0,4.79-1.13,4.79-4.84q-.16-81.21,0-162.44c0-3.84-1.2-4.69-4.84-4.68q-81.23.15-162.44,0c-3.77,0-4.73,1-4.71,4.75C86.45,118,86.38,145,86.38,172Z" />
                                <path d="M.2,120.93C.2,91.11.7,61.27,0,31.45-.41,13.41,13.29-.16,31.49,0,91.64.53,151.8.21,212,.17c5.13,0,10,.4,13.67,4.61,3.84,4.39,5.08,9.33,2.87,14.79s-6.11,8.63-12.05,9.05c-2.16.15-4.33.15-6.49.15-58.33,0-116.65.07-175-.12-5.12,0-6.35,1.34-6.34,6.38.18,59,.1,118,.14,177,0,5.14-.42,10-4.64,13.66-4.4,3.82-9.35,5.08-14.8,2.83s-8.77-6.13-9-12.08c-.25-7-.16-14-.17-21Q.19,158.18.2,120.93Z" />
                            </CopySvg>
                        </CopyContainer>
                    </Container >
                </CopyToClipboard>
                :
                ancor ?
                    <A href={ancor} target="_blank">
                        <Container pointer>
                            {children}
                            <Data
                                title={title}
                                subtitle={subtitle}
                            />
                        </Container >
                    </A>
                    :
                    <Container>
                        {children}
                        <Data
                            title={title}
                            subtitle={subtitle}
                        />
                    </Container>
            }
        </>
    )
}

const A = styled.a`
    text-decoration: none;

`

const MessageContainer = styled.div`
background-color: ${props => props.theme.primaryColorD1};
opacity: 0;
color: ${props => props.theme.primaryCommon};

    ${props => (props.showMessage == true) && css`opacity:1; transition: opacity 100ms ease-out;`}
    transition: opacity 300ms ease-out;
    padding: 2px 4px;
    margin-right:10px;
    border-radius: 4px;
`

const CopyContainer = styled.div`
    position: absolute;
    top: 14px;
    right:14px;
    display: flex;
`

const CopySvg = styled.svg`
    fill: ${props => props.theme.inactive};
    width: 20px;
`

const Container = styled.div`  
     position:relative; 
     background-color: ${props => props.theme.outsideColorBG};
     display: flex;

     padding:20px 0 20px 30px;
     width: 100%;
     margin: 10px 0 0 0;
     border-radius: 30px;

     ${props => props.pointer && css`cursor:pointer;`}

     &:hover svg path{
        fill:${props => props.theme.primaryColor};
     }
`
