import styled from "styled-components";

export const Section = styled.div`
    width:100%;
    height: 100vh;
    background-color: ${props=>props.color};
    display:none;
`
export const PagesContainer = styled.div`
    grid-area: body;
`